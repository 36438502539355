.tooltip {
    --#{$prefix}tooltip-margin: 0 0 0.4rem 0;
    --#{$prefix}tooltip-color: #{$white-color};
    --#{$prefix}tooltip-bg: black;
    --#{$prefix}tooltip-border-radius: #{$border-radius};
    --#{$prefix}tooltip-opacity: 1;
    --#{$prefix}tooltip-arrow-width: 1rem;
    --#{$prefix}tooltip-arrow-height: 0.5rem;
    --#{$prefix}tooltip-max-width: 350px;
    --#{$prefix}tooltip-padding-x: #{$spacer};
    --#{$prefix}tooltip-padding-y: calc(#{$spacer} * 0.5);
    --#{$prefix}tooltip-font-size: 1rem;
    // scrollbar is triggered for a split second when the tooltip first appears, fix by setting position: absolute and opacity: 0 and setting the root of overlay
    position: absolute;
    opacity: 0;
}