// PhoneNumber control
.phoneNumberInputWrappper {
    background-color: $background-color-4;
    transition: background-color .1s;
    &::before {
      content: ""; /* Required for pseudo-elements */
      position: absolute; /* Position it absolutely within the parent */
      top: -2px; /* Adjust based on the thickness of the outline */
      left: -2px; /* Adjust based on the thickness of the outline */
      right: -2px; /* Adjust based on the thickness of the outline */
      bottom: -2px; /* Adjust based on the thickness of the outline */
      border: 3px solid transparent; /* Outline color and thickness */
      border-radius: $border-radius;
      pointer-events: none; /* Ensures the pseudo-element does not interfere with user interactions */
      box-sizing: border-box; /* Ensure the sizing includes the border */
      z-index: 4;
      transition: border-color .1s;
    }
    &.invalid-input { 
      label {
        color: $invalid-main !important;
      }
    }
    border-radius: $border-radius;
    max-height: 58px; // bootstrap default
  
    cursor: text;
  
    .phoneNumberCountryCodeIndicator {
      user-select: none;
    
      .textSeparator {
        display: inline-block;
        background-color: $gray-color;
        width: 1px;
        height: 24px;
        vertical-align: text-top;
      }
    }
  
    .form-control {
      display: inline-block!important;
      background-color: transparent;
      width: unset;
      transition: all 0.2s;
  
      input {
        padding: 0;
        background-color: transparent;
        outline: none;
        &:focus:invalid {
          outline: unset;
          background-color: unset;
        }
      }
    }
  
    label {
      transform: none !important;
      color: white;
      max-width: calc(100% - 58px) !important;
    }
  
    // custom floating-label, because the number textbox is also needs to contain the country code etc and it needs to take that into account
    &:has(#EmailValidationNumber:focus), &:has(#EmailValidationNumber:not(:placeholder-shown)), &:has(.textSeparator) {
      label {
        transform: scale(0.8) translateY(-0.5rem) translateX(0.1rem) !important;
        color: $gray-color;
        max-width: calc((100% - 58px) / 0.8) !important;
      }
    }
  
    &.invalid-input input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus, 
      &:-webkit-autofill:active,
      &:-internal-autofill-selected{
          -webkit-background-clip: text;
          -webkit-text-fill-color: $white-color; // Remove autofill black background set for input elements
          color-scheme: dark;
          -webkit-box-shadow: 0 0 0px 1000px $invalid-background-color inset;;
      }
    }
    & input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus, 
      &:-webkit-autofill:active,
      &:-internal-autofill-selected{
          -webkit-background-clip: text;
          -webkit-text-fill-color: $white-color; // Remove autofill black background set for input elements
          color-scheme: dark;
          -webkit-box-shadow: 0 0 0px 1000px $background-color-4 inset;;
      }
    }
  
  
    .selectorIndicator {
      position: absolute;
      right: 0;
      top: 0;
      width: 58px;
      height: 100%;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
}
.countryFlagIcon {
vertical-align: sub;
border-radius: 5px;
}
div.row { // no fucking way to align the span with input otherwise, for some reason the gutters are impacting the alignment
--bs-gutter-x: 0!important;
--bs-gutter-y: 0!important;
}
/*
--------------------------------------------------------------------------------------------------------
*/