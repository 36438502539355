/* Loading spinner styles */
/* Styles for the spinner container */
.spinner-container {
    position: relative;
}
  
/* Styles for the overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Adjust opacity as needed */
    z-index: 9999;
    /* Ensure the overlay is above other elements */

    // Blur the background content behind the overlay
    backdrop-filter: blur(18px);
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    width: 100px;
    height: 100px;
}

.purpleSpinnerCircle {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    animation: rotate 1s linear infinite;
}

.spinner-text {
    position: fixed !important;
    top: 50% !important;
    margin-top: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    height: 100px;
}

.spinner-text p {
    color: $gray-color;
    margin-top: $spacer;
}

.blueSpinnerCircle {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    animation: rotate 1.5s linear infinite;
}

.greenSpinnerCircle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}