/* Model upload step accordion */
.registrationModelUploadForm {
    $cardBorderWidth: 3px;
    .uploadModelDropdownSection {
      h2 { // cancel text-alignment from _text-alignment-helper because this h2 contains a button that is also aligned and if they are both it overflows(actually the alignment on the button does not work because bootstrap uses :after for the dropdown icon)
        &::before, &::after {
          display: none;
        }
      }
      .accordion {
        --#{$prefix}accordion-color: #{$white-color};
        --#{$prefix}accordion-active-color: #{$white-color};
        --#{$prefix}accordion-btn-color: #{$white-color};
    
        --#{$prefix}accordion-bg: #{$background-color-4};
        --#{$prefix}accordion-border-width: 0;
    
        --#{$prefix}accordion-btn-bg:  #{$background-color-4};
        --#{$prefix}accordion-active-bg: #{$background-color-4};
    
        --#{$prefix}accordion-btn-focus-box-shadow: none;
    
        --#{$prefix}accordion-btn-padding-x: #{$spacer};
        --#{$prefix}accordion-btn-padding-y: calc(#{$spacer} * 1.5);
  
        --#{$prefix}accordion-body-padding-x: 0;
        --#{$prefix}accordion-body-padding-y: 0;
    
        .accordion-button {
          line-height: var(--#{$prefix}body-line-height);
          white-space: pre-line;
    
          --buttonIconFilter: none;
          &:disabled {
            --#{$prefix}accordion-btn-color: #{$gray-color};
            --buttonIconFilter: brightness(0) saturate(100%) invert(66%) sepia(0%) saturate(4506%) hue-rotate(251deg) brightness(91%) contrast(95%);
          }
    
          &:after {
            order: -1; //swap order of the collapsible indicator
            margin-left: 0; 
            margin-right:0.5em; // just extra space between text and icon
  
            margin-top: 0;
            filter: var(--buttonIconFilter);
            // https://icons8.com/icon/Ydq9x6uVsy9e/up - icon used with white color, 1px white stroke and rotation down
            --#{$prefix}accordion-btn-icon: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAElEQVR4nO2YSw6CMBQAy1bxisrlxDVLXGjSeK8xDayQP319NXmTkLigdgawhjpnGIZhGIYRB6AAKuDRH+Fz8Rc+dCc3/NICpYJ82c89pBmN6Oum8Ckj6OTDnFNUY4PCLUI7gmX5QD02sF4YJB7BOvnJgLlHSDyC9fKB29SPuNWIYJt8O7cSnYDXyi/6AJcI8qeoc6aMQGquFBHAWXQOyYhe/i15gcQikslLRCSXjxmhJh8jQl3+SEQ28jv/Nf3Gc0uXgo0ReckLRHiNF6ZYEV5NPkKEvvzO1SnNaiN4J/K58jsi8pUfRDxz2Z45uiEVNgruwFVzg8wwDMMwDMON8wWixLxWXd1DBQAAAABJRU5ErkJggg==") !important;
            --#{$prefix}accordion-btn-active-icon: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAElEQVR4nO2YSw6CMBQAy1bxisrlxDVLXGjSeK8xDayQP319NXmTkLigdgawhjpnGIZhGIYRB6AAKuDRH+Fz8Rc+dCc3/NICpYJ82c89pBmN6Oum8Ckj6OTDnFNUY4PCLUI7gmX5QD02sF4YJB7BOvnJgLlHSDyC9fKB29SPuNWIYJt8O7cSnYDXyi/6AJcI8qeoc6aMQGquFBHAWXQOyYhe/i15gcQikslLRCSXjxmhJh8jQl3+SEQ28jv/Nf3Gc0uXgo0ReckLRHiNF6ZYEV5NPkKEvvzO1SnNaiN4J/K58jsi8pUfRDxz2Z45uiEVNgruwFVzg8wwDMMwDMON8wWixLxWXd1DBQAAAABJRU5ErkJggg==") !important;
          }
        }
    
        .accordion-body {
          position: relative;
  
          &.AdvancedSettings {
            background-color: $background-color-2;

            .videoContainer {
              padding: 0 $spacer;
              cursor: pointer;
              video {
                width: 100%;
                border-radius: $border-radius;
                display: block;
                margin: 0 auto;
              }
            }

            .FloorSeparator {
              input[type="number"] {
                width: 25%;
                flex-shrink: 0;
              }
              .form-control:disabled { // Inputs are disabled when collapsed, to prevent color changes during collapsing we want to remove disabled css styles
                background-color: #{$background-color-4};
              }
              .form-check-input:disabled {
                opacity: 1;
              }
              .form-check-input:disabled ~ .form-check-label {
                opacity: 1;
              }
              .form-check-label.unchecked {
                opacity: 0;
                pointer-events: none;
              }

              .Floors {
                background-color: $background-color-4;

                overflow-y: auto;
                padding: $spacer calc(#{$spacer} - var(--browserScrollbarSize) * 1px) $spacer $spacer;
                scrollbar-gutter: stable;
                max-height: 275px;
                .floorSeparation:not(:first-of-type) {
                  padding-top: $spacer;
                }
              }
            }
          }
  
          .horizontalMenu {
            overflow-x: auto;
            display: flex;
  
            padding-top: calc(#{$spacer} - #{$cardBorderWidth});
            padding-bottom: calc((#{$spacer} - #{$cardBorderWidth}) * var(--isBrowserScrollbarSizeZero));
  
            .card {
              display: inline-block;
              overflow: hidden;
              min-width: 170px;
  
              margin-left: calc(#{$spacer} - #{$cardBorderWidth} - #{$cardBorderWidth});
              &:first-of-type {
                margin-left: calc(#{$spacer} - #{$cardBorderWidth});
              }
              &:last-of-type {
                margin-right: calc(#{$spacer} - #{$cardBorderWidth});
              }
    
              --#{$prefix}card-bg: #{$background-color-2};
              --#{$prefix}card-color: #{$white-color};
              --#{$prefix}card-border-radius: 18px;
              --#{$prefix}card-border-width: #{$cardBorderWidth};
              --#{$prefix}card-border-color: #{$background-color-4};
              --#{$prefix}card-inner-border-radius: 0px;
              --#{$prefix}card-spacer-y: #{$spacer};
              .card-img-top {
                aspect-ratio: 4 / 2.3;
              }

              .card-body {
                text-align: center;
                .card-title {
                  overflow-x: clip;
                  margin: 0;
                  font-weight: 400;
                  text-align: center;
                  text-align: -moz-center;
                  text-align: -webkit-center;
                }
              }
    
              &.selected {
                --#{$prefix}card-border-color: white;
              }
            }
    
            // Custom scrollbar for the horizontal demo model menu
            &::-webkit-scrollbar {
              height: calc((#{$spacer} - #{$cardBorderWidth}) * 2 + 4px); // 4 being by how much the thumb is bigger than track
            };
  
            &::-webkit-scrollbar-thumb {
              border-top : 8px solid transparent;
              border-bottom: 8px solid transparent;
              background-clip: padding-box;
              border-radius: 10px;
            };
            
            &::-webkit-scrollbar-track {
              border-top : 10px solid transparent;
              border-bottom: 10px solid transparent;
              background-clip: padding-box;
              margin-block: $spacer;
              writing-mode: vertical-rl; // for margin-block
            }
          }
        }
      }
  
      .further-info-field {
        padding: $spacer;
  
        li:not(:last-of-type) {
          padding-bottom: $spacer;
        }
      }
    }
  }