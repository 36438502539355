/* Button styles */
.btn {
    --#{$prefix}btn-border-radius: #{$border-radius};
    --#{$prefix}btn-padding-x: #{$spacer};
    --#{$prefix}btn-padding-y: #{$spacer};
    --#{$prefix}btn-border-width: 0;
    --#{$prefix}btn-font-weight: 500;
    --#{$prefix}btn-disabled-opacity: 1;
    --#{$prefix}btn-disabled-color: #{$disabled-text-color};
  
    text-shadow: $text-shadow;
    box-shadow: $base-shadow;
  }
  
.btn-primary {
    --#{$prefix}btn-bg: #{$primary-color};
    --#{$prefix}btn-hover-bg: #{$primary-highlight-color};
    --#{$prefix}btn-active-bg: #{$primary-active-color};
    --#{$prefix}btn-disabled-bg: #{$primary-disabled-color};
}

.btn-secondary {
  --#{$prefix}btn-bg: #{$secondary-color};
  --#{$prefix}btn-hover-bg: #{$secondary-highlight-color};
  --#{$prefix}btn-active-bg: #{$secondary-active-color};
  --#{$prefix}btn-disabled-bg: #{$secondary-disabled-color};
}

.closeBtn {
  --#{$prefix}btn-hover-color: #{$gray-color};
}