/*
  RollingOverflowText
*/
.rollingTextWrapper {
    width: max-content;
    pointer-events: auto;
    
    transition: transform 0.1s linear;
  }
  /*
    ----------------------------------------------------------------------------------------------------------
  */