body {
    &.modal-open {
      padding: unset!important; // idk why bootstrap adds padding right of 5px when modal open, but remove it
    }
}
.modal-backdrop {
    --#{$prefix}backdrop-opacity: 1;
    --#{$prefix}backdrop-bg: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(18px);
}

.mediaModal {
    --#{$prefix}modal-width: 75vw; // max-width
    --#{$prefix}modal-bg: transparent;
    --#{$prefix}modal-border-width: 0px;
    &.modal.fade .modal-dialog {
        transition: all 0.3s ease-out;
        margin-top: -50px;
        transform: none;
    }
    &.modal.show .modal-dialog {
        margin-top: unset;
    }

    .modal-dialog {
        position: static !important;
        justify-content: center;
        .modal-content {
            position: static !important;
            width: unset;
            video, img {
                max-height: 75vh;
                margin: auto;
                max-width: 75vw;
            }
        }
    }
}