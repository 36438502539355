// Once leading-trim is supported in browsers, remove this file and just use it leading-trim, it will be more versatile, supporting more browsers and fonts.
// Generated at https://text-crop.eightshapes.com/ - but i had to change display:block to display: flow-root; otherwise it did not align correctly!!! And added calc, so that
// compiler does not complain about division
@mixin text-crop($line-height: 1.5, $top-adjustment: 0px, $bottom-adjustment: 0px) {
    // Configured in Step 1
    $top-crop: 6;
    $bottom-crop: 6;
    $crop-font-size: 16;
    $crop-line-height: 1.5;

    // Apply values to calculate em-based margins that work with any font size
    $dynamic-top-crop: calc(max(($top-crop + ($line-height - $crop-line-height) * (calc($crop-font-size / 2))), 0) / $crop-font-size);
    $dynamic-bottom-crop: calc(max(($bottom-crop + ($line-height - $crop-line-height) * calc($crop-font-size / 2)), 0) / $crop-font-size);

    // Mixin output
    line-height: $line-height;

    &::before,
    &::after {
        content: '';
        display: flow-root;
        height: 0;
        width: 0;
    }

    &::before {
        margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
    }

    &::after {
        margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
    }
}
/*
  Allows to pixel-perfect align text
  leading-trim-like effect because leading-trim has 0% browser support
*/
h1, h2, h3, h4, h5, h6 {
    @include text-crop(1.2); // #{$headings-line-height} = 1.2 but it is defined in rem, need to strip the unit for this to work
}
p, button, a, .leading-trim, .tooltip-inner {
    @include text-crop(1.5);
}

.label-leading-trim {
    @include text-crop(1.5);
}

.leading-trim-material-icon {
    @include text-crop(1.2, $top-adjustment: -2px, $bottom-adjustment: -2px);
}

// Use this to disable leading-trim adjustments, like for an <a> element that wraps <img>, since there is no text it is not needed there
.leading-trim-disable {
    &::before {
        margin-bottom: 0;
    }

    &::after {
        margin-top: 0;
    }
}