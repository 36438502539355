/* Links */
a {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }
  &:active {
    color: $link-active-color;
  }

  &.iconLink {
    color: $white-color;
  }

  cursor: pointer !important;
}

.links-list {
    list-style-type: none;
    /* Hide default bullet points */
    li {
      position: relative;
      /* To make space for the icon */
      padding-left: $spacer * 2;
      /* Add space for icon and some spacing from text */
      margin-bottom: $spacer * 1.5;
      /* Add some margin between list items */
      &:last-of-type {
        margin-bottom: 0; 
      }
  
      --icon-filter: brightness(0) saturate(100%) invert(67%) sepia(6%) saturate(4015%) hue-rotate(183deg) brightness(101%) contrast(102%);
      &:has(a:hover) {
        --icon-filter: brightness(0) saturate(100%) invert(83%) sepia(5%) saturate(1955%) hue-rotate(188deg) brightness(103%) contrast(105%);
      }
      &:has(a:active) {
        --icon-filter: brightness(0) saturate(100%) invert(52%) sepia(78%) saturate(803%) hue-rotate(192deg) brightness(92%) contrast(96%);
      }
  
      &::before {
        content: '';
        /* Create the bullet point */
        position: absolute;
        top: -3px;
        left: 0;
        width: 20px;
        /* Adjust the size of the icon */
        height: 20px;
        background-size: cover;
        filter: var(--icon-filter);
        // https://icons8.com/icon/qD4mOc3abDh0/link
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAArUlEQVR4nO2TTQrCMBCFvysILoyXkhavIaL2FELBG9X2Rv5A3UUCbxFCskjbhUI+yOZN5s1MmECh8HfsgQF4AzY4IWHc5fRAnTK/RZJsRgH/tLHOXeADHID1hOkNcAJGeVV+cJB4ZD5ned198SVxlfEMNvF8RtozVmCzQIGttIcv9hLdeHNp5NX5Yi1xVBE3Zi4GuGhRnNcuvNAuuKbXVBeVRpv60bpY54XCj/MFS5BmNPxoxQIAAAAASUVORK5CYII=");
      }
    }
  }