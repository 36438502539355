.tagBox {
    white-space: nowrap;
    overflow: auto;
    background-color: $background-color-4;
    border-radius: $border-radius;
    overflow: hidden;
    padding-left: $spacer;
    padding-right: $spacer;
    .form-control {
        width: unset;
        display: unset;
        padding-right: 0;
        padding-left: 0;
        background-color: transparent !important;
        outline: none !important;
    }
    position: static;
    outline: 3px solid transparent !important;
    transition: background-color .1s, outline-color .1s;

    --fadeOffset: #{$spacer};
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-right: $spacer solid $background-color-4;
        border-left: $spacer solid $background-color-4;
        pointer-events: none;
        border-radius: $border-radius;
    }

    &.invalid-input {
        label {
            color: $invalid-main !important;
        }
    }

    &:has(.form-control:disabled) label {
        color: $gray-color;
    }

    .tag {
        display: inline-block;
        background-color: $background-color-1;
        padding: $spacer * 0.5;
        border-radius: $border-radius;
        border-bottom-left-radius: $spacer; 
        border-bottom-right-radius: $spacer * 2;
        border-top-right-radius: $spacer * 2;
        border-top-left-radius: $spacer;
        &:not(:first-of-type) {
            margin-left: $spacer * 0.5;
        }
        &:last-of-type + .form-control:not(:disabled) {
            margin-left: $spacer * 0.5;
        }
    }
    & > .tag ~ label {
        color: $gray-color;
        transform: scale(0.8) translateY(-0.5rem) translateX(0.1rem);
    }
    & > .tag ~ .form-control {
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;
    }
}

.btn-close-small {
    @include text-crop(1.5, $top-adjustment: -5px, $bottom-adjustment: -2px);
    --#{$prefix}btn-color: #{$invalid-main};
    font-size: 1.2rem;
    box-shadow: none;
    border-radius: 20px;
    background-color: $background-color-3;
    padding: 0;
    margin-bottom: 3px;
    margin-left: 5px;
    --#{$prefix}btn-hover-color: #{$invalid-main};
    --#{$prefix}btn-hover-bg: #{$background-color-2};
  
    width: 1rem;
    height: 1rem;

    .closeSign {
        margin-right: -1px;
        pointer-events: none;
    }
  }