/* Input styles */
@mixin invalidInput() {
    outline: $invalid-border !important;
    background-color: $invalid-background-color;
}
  input {
    --#{$prefix}body-bg: #{$background-color-4};
    --#{$prefix}body-color: #{$white-color};
    //--#{$prefix}border-width: 0px; This var is used by bootstrap in calculating height of floating labelled inputs
    border: none;
    padding: 10px;
    --#{$prefix}border-radius: #{$border-radius};
  }
  
  .form-control {
    border: none;
    transition: background-color .1s, outline-color .1s;
    outline: 3px solid transparent !important;
    &:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px rgb(28, 28, 28); // Remove browser-added box-shadow when focus
    }

  }
  .form-floating > .form-control.invalid-input ~ label {
    color: $invalid-main !important;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active,
  input:-internal-autofill-selected{
      //-webkit-background-clip: text;
      -webkit-text-fill-color: $white-color; // Remove autofill white background set by browser
      color-scheme: dark;
      -webkit-box-shadow: 0 0 0px 1000px $background-color-4 inset;
  }



input::-webkit-outer-spin-button, // remove arrows on input type number
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}


/* 
  Make placeholders visible in bootstrap floating labels and change floating labels colors
*/
// Make placeholder visible, since by default it is not in bootstrap
.form-floating > .form-control:focus::placeholder, .form-floating > .form-control-plaintext:focus::placeholder {
  color: $gray-color;
}

// Make label whiter, more visible
.form-floating > label {
  --#{$prefix}border-width: 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, color 0.1s ease-in-out;
  max-width: calc(100%);
  overflow-clip-margin: content-box;
  overflow: clip;
  cursor: text;
}
// Once label floats, make it a little less light
.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: $gray-color;
  max-width: calc(100% / 0.8);
}

// Remove the background that is added to label when it floats to the top
.form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after {
  --#{$prefix}body-bg: transparent;
}
  

/* Radiobuttons */
.form-check {
    display: flex;
    align-items: center;
    gap: 9px;
    margin-bottom: 0;
    padding-right: $spacer;
}
.form-check-input {
  margin-top: 0;
  box-shadow: none !important;
  background-color: transparent !important;
  border-color: $white-color !important;
  border-width: 3px;
  transform: scale(0.75);
  margin-top: 2px;
  margin-right: 0 !important;

  background-size: 140%;

  &[type="checkbox"] {
    // Taken from: https://icons8.com/icon/5576/done
    --bs-form-check-bg-image:  url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAcklEQVR4nO2TQQrAIAwE5xUq/aJ9bqntayyFHIoVtBgvJQPezJrdRDCMDwQgzhRPQAZWbXEPbCJ+AIuJD8cSZWg9A02Nuy/WRmEYEb9xD+tnYV1tW2qPeO1VLKNII7H0OMkzPhHS7S5HrfOaEzdL3PgBF8KgLcE52MzvAAAAAElFTkSuQmCC") !important;
    &:checked {
      background-color: white !important;
    }
  }
}