.SwitchButtonWrapper {
    position: relative;
    display: inline-block;
    background-color: $background-color-4;
    border-radius: $border-radius;
    cursor: pointer;

    height: calc(1.5 * 1rem + #{$spacer}); // equals height of text-box. 1.5 is line-height and $spacer is the sum of top and bottom padding

    button {
        background-color: transparent;
        border: none;

        width: 50%;
        height: 100%;
        @extend .leading-trim-material-icon;
    }

    .icon {
        position: relative;
        pointer-events: none;
    }
  
    /* The slider */
    .slider {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-radius: $border-radius;
      background-color: $invalid-main;
      -webkit-transition: .2s;
      transition: .2s;
      @include xShiftedBoxShadow(-3px);
    }
    
    &[aria-checked="true"] .slider {
      -webkit-transform: translateX(50%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
      background-color: $twinzo-green;

      @include xShiftedBoxShadow(3px);
    }
}
  