/*
  Custom vertical scrollbar for the whole website
*/
* {
    @supports not selector(::-webkit-scrollbar) {
      scrollbar-color: $white black;
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar {
      width: #{$scrollbarSize};
      height: #{$scrollbarSize};
    }
  
    &::-webkit-scrollbar-thumb {
      background: $white;
      border-radius: $border-radius;
    }
  
    &::-webkit-scrollbar-track {
      background: black;
      border-radius: $border-radius; 
      // track unfortunately cannot be made smaller by giving it a transparent and setting background-clip: padding-box;, because body bg is a gradient but the bg of the trackpad
      // for some reason when the bg is a gradient is a solid gray color
    }
  }

  body {
    *::-webkit-scrollbar {
      width: calc(#{$spacer} - 2px);
    }
    *::-webkit-scrollbar-thumb:vertical {
      border-right : 2px solid transparent;
      border-left: 2px solid transparent;
      background-clip: padding-box;
      border-radius: 20px;
    };
    
    *::-webkit-scrollbar-track:vertical {
      border-left : 4px solid transparent;
      border-right: 4px solid transparent;
      background-clip: padding-box;
      margin-block: $spacer;
      writing-mode: horizontal-tb; // for margin-block
    }
  }
  /*
    ----------------------------------------------------------------------------------------------------------
  */
