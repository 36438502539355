.fileInput {
    display: flex;
    cursor: pointer;
    transition: background-color .1s, outline-color .1s;
    outline: 3px solid transparent !important;
   
    border-radius: $border-radius;
    background-color: $background-color-4;
    label {
      flex-grow: 1;
      font-size: 1.2rem;
      padding: #{$spacer};
      pointer-events: none;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    button {
      flex-shrink: 0;
      font-size: 1.75rem;
      font-weight: 900;
      letter-spacing: 5px;
      padding-right: calc($spacer - 5px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @include text-crop($line-height: 1.5, $top-adjustment: 2px, $bottom-adjustment: 0px);
      line-height: 0;
      pointer-events: none;
    }
  
    &:hover button {
      background-color: $primary-highlight-color;
    }
    &:active button {
      background-color: $primary-active-color;
    }
  }